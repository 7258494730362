import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { BinanceConnector } from "@bscswap/binance-connector";
const NETWORK_URL = "https://bsc-dataseed1.defibit.io";

export const injected = new InjectedConnector({
  supportedChainIds: [56, 42],
});

export const mathWalletInjected = new InjectedConnector({
  supportedChainIds: [56],
});

export const walletconnect = new WalletConnectConnector({
  rpc: {
    56: NETWORK_URL,
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 15000,
});

export const walletconnectMainnet = new WalletConnectConnector({
  rpc: {
    1: "wss://mainnet.infura.io/ws/v3/2731597d41324dfbba606b6bca52f636'",
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 15000,
});

export const binanceinjected = new BinanceConnector({
  supportedChainIds: [56],
});

export const SUPPORTED_WALLETS = [
  {
    name: "METAMASK",
    data: {
      connector: injected,
      name: "MetaMask",
      iconName: "/images/metamask-fox.svg",
      description: "Easy-to-use browser extension.",
      href: null,
      color: "#E8831D",
    },
  },
  {
    name: "BINANCE",
    data: {
      connector: binanceinjected,
      name: "Binance Chain",
      iconName: "/images/bcw.svg",
      description: "A Crypto Wallet for Binance Smart Chain",
      href: null,
      color: "#F9A825",
    },
  },
  {
    name: "TRUSTWALLET",
    data: {
      connector: injected,
      name: "Trust Wallet",
      iconName: "/images/trust.svg",
      description: "The most trusted & secure crypto wallet",
      href: null,
      color: "#3375BB",
    },
  },
  {
    name: "MATHWALLET",
    data: {
      connector: mathWalletInjected,
      name: "Math Wallet",
      iconName: "/images/math.png",
      description: "Your Gateway to the World of Blockchain",
      href: null,
      color: "#000000",
    },
  },
  {
    name: "WALLETCONNECT",
    data: {
      connector: walletconnect,
      name: "Wallet Connect",
      iconName: "/images/walletConnect.png",
      description: "Your Gateway to the World of Blockchain",
      href: null,
      color: "#000000",
    },
  },
];
