import React, { useState, useEffect } from "react";
import { AiOutlineMedium } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import SelectBlockChainPopup from "./SelectBlockChainPopup";
export default function Follow() {
  const contract = "0xaac073847e989c6d9d2f115a69e57a024415c684";
  const [openSelectBlockchain, setOpenSelectBlockchain] = useState(false);

  useEffect(() => {
    if (openSelectBlockchain) {
      window.$("#connectWallet").modal("show");
    } else {
      window.$("#connectWallet").modal("hide");
    }
  }, [openSelectBlockchain]);

  return (
    <>
      {openSelectBlockchain && (
        <SelectBlockChainPopup
          open={openSelectBlockchain}
          handleClose={() => setOpenSelectBlockchain(false)}
        />
      )}

      <div className="followdiv">
        {/* <a href="https://medium.com/gainpool" target="_blank"><AiOutlineMedium/></a> */}
        <a href="https://twitter.com/GainPool" target="_blank">
          <BsTwitter /> Twitter
        </a>
        {/* <a href="https://www.linkedin.com/company/gainpool/" target="_blank"><FaLinkedinIn/></a>
          <a href="https://github.com/GainPool" target="_blank"><AiFillGithub/></a> */}
        <a
          href="https://t.me/GainPoolcommunity"
          target="_blank"
          style={{ marginLeft: "10px" }}
        >
          <FaTelegramPlane /> Telegram
        </a>
        <button
          className="btn connect"
          onClick={() =>
            window.open(`https://pancakeswap.finance/swap/${contract}`)
          }
        >
          Buy Gain
          <img src="images/icon.png" style={{ marginLeft: "15px" }} />
        </button>
        <button
          className="btn connect connect1"
          onClick={() => window.open(`https://bridge.gainpool.io/`)}
        >
          Bridge
          {/* <img
            src="images/soon.png"
            style={{ marginLeft: "15px", width: "50px" }}
          /> */}
          <span>
            {" "}
            {/* Liquidity{" "} */}
            {/* <img
              src="images/soon.png"
              style={{ width: "50px", marginLeft: "10px", borderRadius: "0%" }}
            /> */}
          </span>
        </button>
        <button
          className="btn connect connect1"
          onClick={() => window.open(`https://launchpad.gainpool.io/dashboard`)}
        >
          Pools
        </button>
        <button
          className="btn connect connect1"
          onClick={() => setOpenSelectBlockchain(true)}
        >
          Select Blockchain
        </button>
      </div>
    </>
  );
}
