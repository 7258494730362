import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageLayout from "./components/PageLayout";
import PageLoader from "./components/PageLoader";
import AuthContext from "./context/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function App() {
  return (
    <AuthContext>
      <Router>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <PageLayout>
              <Route
                path="/personal"
                exact={true}
                component={lazy(() => import("./screens/Personal"))}
              />
              <Route
                path="/leaderboard"
                exact={true}
                component={lazy(() => import("./screens/Leaderboard"))}
              />
              <Route
                path="/404"
                component={lazy(() => import("./screens/PageNotFound"))}
              />
              <Route
                exact={true}
                path="/"
                component={lazy(() => import("./screens/home"))}
              />
            </PageLayout>
          </Switch>
        </Suspense>
      </Router>
      <ToastContainer />
    </AuthContext>
  );
}
