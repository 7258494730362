export const NetworkContextName = "Kovan Test Network";
export const ACTIVE_NETWORK = 42;
export const AdminWalletAddress = "";
export const RPC_URL = "https://bsc-dataseed1.defibit.io";

export const ContrsctAddressBNB = "0xbeE3356964f9c4C8feB373242F420A5e4057794b";
export const ContrsctAddressETH = "0x428508dfa4549Cc9a46CB4A0B1ef59CC99FE8ed5";
export const tokenAddressBNB = "0xAAc073847e989C6D9d2f115A69E57a024415c684";
export const tokenAddressETH = "0xDf1be47a95980Fb475F3D4D9cC7d82f6A2877488";

export const getNetworkDetails = (chianId) => {
  switch (chianId.toString()) {
    case "42":
      return [
        {
          chainId: "0x2A",
          chainName: "Kovan Test Network",
          nativeCurrency: {
            name: "Kovan Test Network",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: [
            "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          ],
          blockExplorerUrls: ["https://kovan.etherscan.io"],
        },
      ];
    case "97":
      return [
        {
          chainId: "0x61",
          chainName: "Smart Chain - Testnet",
          nativeCurrency: {
            name: "Smart Chain - Testnet",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com"],
        },
      ];
    case "56":
      return [
        {
          chainId: "0x38",
          chainName: "Smart Chain",
          nativeCurrency: {
            name: "Smart Chain",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://bsc-dataseed1.defibit.io"],
          blockExplorerUrls: ["https://bscscan.com"],
        },
      ];
  }
};

export const networkList = [
  {
    name: "Binance Mainnet",
    chainId: "56",
    description: "",
    symbol: "BNB",
  },
  //   {
  //     name: "Binance",
  //     chainId: "97",
  //     description: "",
  //     symbol: "BNB",
  //   },
  {
    name: "Ethereum",
    chainId: "42",
    description: "",
    symbol: "ETH",
  },
];

export const getCurrentContractAddress = (chianId) => {
  switch (chianId.toString()) {
    case "42":
      return ContrsctAddressETH;
    case "97":
      return ContrsctAddressBNB;
    case "56":
      return ContrsctAddressBNB;
  }
};

export const getCurrentContractTokenAddress = (chianId) => {
  switch (chianId.toString()) {
    case "42":
      return tokenAddressETH;
    case "97":
      return tokenAddressBNB;
    case "56":
      return tokenAddressBNB;
  }
};
