import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import React from "react";
import FollowBox from "./FollowBox";
function closeNav() {
  document.getElementById("mySidenav").style.width = "0%";
}
export default function NavBar() {
  const history = useHistory();
  const currentPage = history.location.pathname;

  const navToggle = () => {
    if (window.$("#mySidenav").hasClass("closed")) {
      window.$("#mySidenav").removeClass("closed");
    } else {
      window.$("#mySidenav").addClass("closed");
    }
  };

  return (
    <>
      <div className="side-menu closed " id="mySidenav">
        <div className="closemenu" onClick={navToggle}></div>
        <div className="logo">
          {" "}
          <Link to="/">
            <img src="images/logo.png" alt="" />
          </Link>
        </div>
        <ul className="">
          <li className={` ${currentPage === "/" ? "active" : null}`}>
            <Link to="/">
              {" "}
              {/* <i className="csicon icon-dash"></i> */}
              <img src="images/icons/staking.png" />
              <span> Staking</span>
            </Link>
          </li>
          {/* <li
            className={` ${currentPage === '#' ? 'active' : null}`}
          >
            <Link to="#">
              {' '}
              <img src="images/icons/liquidity.png" />
              <span> Liquidity <img src="images/soon.png" style={{width:"50px",marginLeft:"10px",}} /></span>
            </Link>
          </li> */}

          <li className={` ${currentPage === "/personal" ? "active" : null}`}>
            <Link to="/personal">
              {" "}
              {/* <i className="csicon icon-databse"></i> */}
              <img src="images/icons/personal.png" />
              <span> Personal Stats</span>
            </Link>
          </li>
          <li
            className={` ${currentPage === "/leaderboard" ? "active" : null}`}
          >
            <Link to="/leaderboard">
              {" "}
              {/* <i className="csicon icon-databse"></i> */}
              <img src="images/icons/leaderboard.png" />
              <span> Leaderboard</span>
            </Link>
          </li>
          <li className={` ${currentPage === "#" ? "active" : null}`}>
            <a href="https://launchpad.gainpool.io/">
              {" "}
              {/* <i className="csicon icon-databse"></i> */}
              <img src="images/icons/gpad.png" />
              <span> GPad (TDE Launchpad)</span>
            </a>
          </li>
        </ul>
        <div className="hiddenxs">
          <FollowBox />
        </div>
      </div>
    </>
  );
}
