import React, { createContext, useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { injected } from "./connectors";
import { getNetworkDetails, networkList } from "../constants";
import { swichNetworkHandler } from "../utils";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("accessToken", accessToken);
  } else {
    sessionStorage.removeItem("accessToken");
  }
};
const setSessionAddress = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, chainId, deactivate, library, account } = useWeb3React();

  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState({});
  const [adData, setAdData] = useState("");
  const [ethBTCValue, setEthBTCValue] = useState();
  const [topTenCounList, settoptenCounList] = useState();
  const [telegramInfo, setTelegramInfo] = React.useState();
  const [unReadCount, setUnReadCount] = useState(0);
  // const [services, setServices] = useState([]);
  const [cardWishListData, setCardWishListData] = useState([]);
  const [userType, setUserType] = useState("USER");
  const [isLoading, setIsLoading] = useState(false);
  const [notificationList, setNotificationList] = useState();
  const [addStatus, setAddStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedBlockchain, setSelectedBlockchain] = useState(networkList[0]);
  const [selectedNetworkDetails, setSelectedNetworkDetails] = useState();

  useEffect(() => {
    if (selectedBlockchain.chainId) {
      if (account && chainId != selectedBlockchain.chainId) {
        swichNetworkHandler(selectedBlockchain.chainId);
      }
      setSelectedNetworkDetails(
        getNetworkDetails(selectedBlockchain.chainId)[0]
      );
    }
  }, [selectedBlockchain, chainId]);

  const disconnectWallethandler = () => {
    setSession(null);
    setIsLogin(false);
    setUserData({});
    setUserType("USER");
    deactivate();
    window.sessionStorage.removeItem("userAddress");
  };

  let data = {
    selectedBlockchain,
    selectedNetworkDetails,
    addStatus,
    errorMsg,
    userLoggedIn: isLogin,
    adData: adData,
    isLoading: isLoading,
    userType: userType,
    telegramInfo: telegramInfo,
    ethBTCValue: ethBTCValue,
    topTenCounList: topTenCounList,
    cardWishListData: cardWishListData,
    notificationList: notificationList,
    unReadNotificaionCount: unReadCount,
    setSelectedBlockchain: (data) => setSelectedBlockchain(data),

    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected);
        }
      });
    },
    userData,
    disconnectToWallet: () => {
      disconnectWallethandler();
    },
    updateUser: (account) => {
      setSessionAddress(account);
    },
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []);
  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line
  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
