import { networkList } from "../constants/index";
import React, { useContext } from "react";
import { AuthContext } from "../context/Auth";

export default function SelectBlockChainPopup({ open, handleClose }) {
  const auth = useContext(AuthContext);
  window.$("#connectWallet").on("hidden.bs.modal", function (e) {
    handleClose();
  });

  return (
    <>
      <div
        className="modal fade"
        id={"connectWallet"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div
            className="modal-content eth-buttons-content"
            style={{
              width: "100%",
              padding: 25,
              backgroundColor: "#10171f",
              Width: 600,
              maxWidth: "100%",
              textAlign: "center",
              color: "white",
            }}
          >
            <h5>Select Blockchain</h5>
            <div className="row mt-3">
              {networkList.map((data, i) => {
                return (
                  <div className="col-md-12 mt-3">
                    <button
                      className="btn connect connect1"
                      style={
                        auth.selectedBlockchain.name === data.name
                          ? { minWidth: "70%" }
                          : {
                              backgroundColor: "#212529",
                              borderColor: "#27B5E7",
                              minWidth: "70%",
                            }
                      }
                      onClick={() => {
                        auth.setSelectedBlockchain(data);
                        setTimeout(() => {
                          window.$("#connectWallet").modal("hide");
                          handleClose();
                        }, 500);
                      }}
                    >
                      {data.name}
                    </button>
                  </div>
                );
              })}

              <div className="col-md-12 mt-5">
                <button
                  className="btn connect connect1"
                  style={{
                    backgroundColor: "#212529",
                    borderColor: "#27B5E7",
                  }}
                  onClick={() => {
                    window.$("#connectWallet").modal("hide");
                    handleClose();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
